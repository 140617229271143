import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { history, request, SlideVerify } from 'egenie-common';
import * as _ from 'lodash';
import { action, observable } from 'mobx';
import React from 'react';
import { api } from '../../utils';
import { emailReg, passwordReg, phoneReg } from '../common';
import type { FindPasswordForm, FloorList, Market, Response, SelectOption } from './interface';

export class Store {
  constructor(options) {
    this.logoImg = options.route?.logoImg;
    this.logoText = options.route?.logoText;
    const params = window.location.href.split('?')[1];
    const search = new URLSearchParams(params);
    this.accountType = search.get('accountType') ? Number(search.get('accountType')) : '';
    this.loginPath = search.get('loginPath') || '';
  }

  public loginPath: string;

  public logoImg: string;

  public logoText: string;

  public accountType: number | string;

  /*
  *注册
  */
  @observable public registryFormData = {
    retailType: {
      label: '经营类型',
      required: true,
      rules: [
        {
          required: true,
          message: '请选择经营类型',
        },
      ],
      type: 'select',
      placeholder: '请选择经营类型',
      options: [],
      systemType: [
        'ERP',
        'SIMPLEERP',
      ],
    },
    mainCategories: {
      label: '主营',
      required: true,
      rules: [
        {
          required: true,
          message: '请选择主营',
        },
      ],
      type: 'select',
      mode: 'multiple',
      placeholder: '请选择主营',
      options: [],
    },
    tenantName: {
      label: '公司名称',
      required: true,
      systemType: [
        'ERP',
        'SIMPLEERP',
        'POS-STALL',
      ],
      rules: [
        {
          required: true,
          message: '请输入公司名称',
        },
      ],
      type: 'input',
      placeholder: '请输入公司名称',
    },
    storeName: {
      label: '门店名称',
      required: true,
      systemType: 'POS-STORE',
      rules: [
        {
          required: true,
          message: '请输入门店名称',
        },
      ],
      type: 'input',
      placeholder: '请输入门店名称',
    },
    factoryName: {
      label: '工厂名称',
      required: true,
      systemType: 'POS-FACTORY',
      rules: [
        {
          required: true,
          message: '请输入工厂名称',
        },
      ],
      type: 'input',
      placeholder: '请输入工厂名称',

    },
    shortName: {
      label: {
        ERP: '档口拿货简称',
        SIMPLEERP: '公司简称',
      },
      tooltip: { ERP: '请填写在档口拿货时拿货条码上使用的名称，如果没有请填写店铺简称。' },
      required: true,
      differentLabel: true,
      systemType: [
        'ERP',
        'SIMPLEERP',
      ],
      rules: [
        {
          required: true,
          message: '请输入',
        },
      ],
      type: 'input',
      placeholder: {
        ERP: '请输入档口拿货条码上使用的名称/店铺简称',
        SIMPLEERP: '请输入公司简称',
      },
    },
    address: {
      label: '地址',
      required: true,
      systemType: [
        'ERP',
        'SIMPLEERP',
        'POS-STORE',
        'POS-FACTORY',
      ],
      rules: [
        {
          required: true,
          message: '请填写地址',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value.address || !value.districtId) {
              return Promise.reject('请填写地址');
            }
            return Promise.resolve();
          },
        }),
      ],
      type: 'address',
      placeholder: '',
      options: [],
    },
    shopNo: {
      label: '档口号',
      required: true,
      systemType: 'POS-STALL',
      rules: [
        {
          required: true,
          message: '请输入档口号',
        },
        {
          validateTrigger: 'onBlur',
          validator(_, value) {
            return request({ url: `${api.queryRepeatShop}?shopNo=${value}` }).then((res: { data: boolean; }) => {
              if (res.data) {
                return Promise.reject('档口号已经存在');
              }
              return Promise.resolve();
            })
              .catch(() => {
                console.log('xxxx');
              });
          },
        },
      ],
      type: 'input',
      placeholder: '请输入档口号',
    },
    shopSign: {
      label: '店招',
      systemType: 'POS-STALL',
      type: 'input',
      placeholder: '请输入门店招牌',
    },
    contact: {
      label: '联系人',
      required: true,
      rules: [
        {
          required: true,
          message: '请输入联系人姓名',
        },
      ],
      type: 'input',
      placeholder: '请输入联系人姓名',

    },
    mobile: {
      label: '账号',
      required: true,
      rules: [
        {
          required: true,
          pattern: phoneReg,
          message: '请输入正确手机号(11位)',
          trigger: ['onChange'],
        },
        {
          validateTrigger: 'onBlur',
          validator(_, value) {
            if (!phoneReg.test(value)) {
              return Promise.resolve('请输入正确手机号(11位)');
            }

            return Promise.resolve();
          },
        },
      ],
      type: 'input',
      placeholder: '请输入手机号码',

    },
    password: {
      label: '密码',
      required: true,
      rules: [
        {
          required: true,
          message: '请输入密码',
        },
        {
          pattern: passwordReg,
          message: '密码格式不正确',
        },
      ],
      type: 'input',
      prop: 'password',
      placeholder: '请输入密码',
      help: '密码需包括字母和数字，且长度不小于8位',
    },
    repeatPassword: {
      label: '确认密码',
      required: true,
      rules: [
        {
          required: true,
          message: '请再次输入密码',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject('两次输入密码不一致');
          },
        }),
      ],
      type: 'input',
      prop: 'password',
      placeholder: '请再次输入密码',

    },
    smsCode: {
      label: '验证码',
      required: true,
      rules: [
        {
          required: true,
          message: '请输入验证码',
        },
      ],
      type: 'input',
      placeholder: '请输入验证码',

    },
    email: {
      label: '邮箱地址',
      rules: [
        {
          required: false,
          message: '请输入邮箱地址',
        },
        {
          patter: emailReg,
          message: '邮箱格式错误',
        },
      ],
      type: 'input',
      placeholder: '请输入邮箱地址',
    },
    shopName: {
      label: '档口名称',
      required: true,
      systemType: 'POS-STALL',
      type: 'input',
      placeholder: '请输入',
    },
  };

  @observable public POSRegistryFormData = [
    {
      title: '',
      children: {
        inviteCode: {
          label: '邀请码',
          type: 'input',
          systemType: [
            'POS-STALL',
            'POS-FACTORY',
          ],
          placeholder: '请输入',
        },
      },
    },
    {
      title: '账号信息',
      children: {
        mobile: this.registryFormData.mobile,
        password: this.registryFormData.password,
        repeatPassword: this.registryFormData.repeatPassword,
        smsCode: this.registryFormData.smsCode,
      },
    },
    {
      title: '经营信息',
      children: {
        tenantName: this.registryFormData.tenantName,
        mainCategories: this.registryFormData.mainCategories,
        factoryName: this.registryFormData.factoryName,
        address: this.registryFormData.address,
        contact: this.registryFormData.contact,
      },
    },
  ];

  @observable public countDown = 60;

  @observable public timer;

  @observable public systemType = 'POS-STALL'; // null, ERP, POS-STALL, POS-FACTORY, SIMPLEERP

  @observable public stepType = 0; // 0-选择系统, 1-注册, 2-注册成功

  @observable public registryDisabled = true;

  @observable public registryformRef = React.createRef<FormInstance>();

  @observable public marketData: Market[] = [];

  @observable public marketFloor: SelectOption[] = [];

  @observable public stallInfo: []; // 档口信息

  public goToLogin = action(() => {
    this.stepType = 0;
    this.registryDisabled = true;
    this.isSendCode = false;
    this.countDown = 60;
    history.push(this.loginPath);
  });

  // 修改POS中不同tab的表单
  public changePosTab = action((key) => {
    this.systemType = key;
    this.registryformRef?.current?.resetFields();
  });

  // 获取经营类型
  public queryRetailType = action(() => {
    request({ url: `${api.findDict}?type=retail_type` }).then((res: Response) => {
      const options = res.data.map((item) => {
        return {
          value: item.key,
          name: item.val,
        };
      });
      this.registryFormData.retailType.options = options;
    });
  });

  // 处理市场楼层数据
  @action public loadMarketData = (selectedOptions: SelectOption[]): void => {
    const { length } = selectedOptions;
    if (length === 1) {
      // 一级联动
      const targetOption = selectedOptions[0];
      targetOption.loading = true;
      request({
        method: 'post',
        url: api.findByCityIds,
        data: [targetOption.value],
      }).then((res: { data?; }) => {
        if (!res) {
          targetOption.loading = false;
          return;
        }
        const childData = res.data?.map((item) => ({
          ...item,
          label: item.marketName,
          value: item.id,
          isLeaf: false,
        }));
        Object.assign(targetOption, { children: childData.length ? childData : []});
        targetOption.loading = false;
        this.marketFloor = this.marketFloor.concat([]);
      });
    } else if (length === 2) {
      // 二级联动
      const targetOption = selectedOptions[1];
      targetOption.loading = true;
      request({ url: `${api.findFloor}?marketId=${targetOption.value}` }).then((res: BaseData<FloorList[]>) => {
        const childData = [];
        res.data?.forEach((item) => {
          if (item.hasBlock) {
            item.blockList.forEach((block) => {
              childData.push({
                label: item.floor + block.blockName,
                value: `${item.id },${ block.blockId}`,
              });
            });
          } else {
            const blockId = item.blockList && item.blockList[0] ? item.blockList[0]?.blockId : '';
            childData.push({
              ...item,
              label: item.floor,
              value: blockId ? `${item.id},${blockId}` : item.id,
            });
          }
        });
        Object.assign(targetOption, { children: childData.length ? childData : []});
        targetOption.loading = false;
        this.marketFloor = this.marketFloor.concat([]);
      });
    }
  };

  // 获取城市
  @action public getCity = (): void => {
    request({ url: api.findMarketCity }).then((res: { data: SelectOption[]; }) => {
      this.marketFloor = res.data?.map((item) => ({
        label: item.cityName,
        value: item.cityId,
        isLeaf: false,
      }));
    });
  };

  // 获取主营分类
  public getMarketList = action(() => {
    request({ url: `${api.findOneLevel}?categoryType=25&parentCategoryId=0` }).then((res: Response) => {
      const options = res.data.map((item) => {
        return {
          value: item.id,
          name: item.name,
        };
      });
      this.registryFormData.mainCategories.options = options;
    });
  });

  public goBackChoice = action(() => {
    this.stepType -= 1;
    this.registryDisabled = true;
    this.isSendCode = false;
    this.countDown = 60;
    this.volidMobile = null;
  });

  public changeSystemType = action((id: string) => {
    this.systemType = id;
    this.stepType += 1;
  });

  public handleFieldChange = action((changedFields, allFields) => {
    const agreement = allFields.find((item) => item.name.includes('agreement')).value;
    const emptyData = allFields.filter((item) => !item.value);
    if (changedFields[0].name[0] === 'mobile') {
      this.volidMobile = changedFields[0].value;
    }

    // 必填项全部不为空按钮才能点击
    const notRequiredFields = [
      'emial',
      'shopSign',
    ];
    this.registryDisabled = !agreement || (emptyData.length > 3) || (emptyData.length === 3 && !notRequiredFields.includes(emptyData[0].name[0]));
  });

  // 提交注册
  public handleRegistrySubmit = action(async(): Promise<void> => {
    const data = await this.registryformRef.current.validateFields();
    const address = data.address;
    delete data.address;
    delete data.repeatPassword;
    delete data.agreement;

    const shopInfoList = [];
    if (this.systemType === 'POS-STALL') {
      if (data.firstShop) {
        if (!data.firstShop.marketFloor[2]) {
          message.error('市场楼层选择不完整，请选择到具体楼层');
          return;
        }
        shopInfoList.push({
          shopName: data.firstShop.shopName,
          shopNo: data.firstShop.shopNo,
          marketId: data.firstShop.marketFloor[1],
          floorId: data.firstShop.marketFloor[2]?.split(',')[0],
          blockId: data.firstShop.marketFloor[2]?.split(',')[1] || undefined,
        });
      }
      if (data.stallInfo?.length > 0) {
        if (data.stallInfo.some((item) => !item.marketFloor[2])) {
          message.error('市场楼层选择不完整，请选择到具体楼层');
          return;
        }
        data.stallInfo.forEach((item) => {
          shopInfoList.push({
            shopName: item.shopName,
            shopNo: item.shopNo,
            marketId: item.marketFloor[1],
            floorId: item.marketFloor[2]?.split(',')[0],
            blockId: item.marketFloor[2]?.split(',')[1] || undefined,
          });
        });
      }
    }

    // 根据注册系统类型决定传参
    let tenantModule;
    switch (this.systemType) {
      case 'ERP':
        tenantModule = 100001;
        break;
      case 'POS-STALL':
        tenantModule = 100002;
        break;
      case 'POS-STORE':
        tenantModule = 100002;
        break;
      case 'POS-FACTORY':
        tenantModule = 100002;
        break;
      case 'SIMPLEERP':
        tenantModule = 100018;
        break;
    }

    // 根据POS中tab选择决定传参
    let shopType;
    switch (this.systemType) {
      case 'POS-STALL':
        shopType = 1;
        break;
      case 'POS-STORE':
        shopType = 2;
        break;
      case 'POS-FACTORY':
        shopType = 3;
        break;
    }

    // 根据POS中tab选择决定传参
    let tenantName = data.tenantName;
    switch (this.systemType) {
      case 'POS-STORE':
        tenantName = data.storeName;
        break;
      case 'POS-FACTORY':
        tenantName = data.factoryName;
        break;
    }
    const postData = {
      ...(_.omit(data, [
        'firstShop',
        'stallInfo',
      ])),
      ...address,
      shopInfoList,
      tenantModule,
      shopType,
      sysType: 'pc',
      tenantName,
    };
    request({
      url: this.systemType.includes('POS') ? api.registerSupplier : api.registerRetailer,
      method: 'post',
      data: postData,
    }).then(() => {
      this.stepType += 1;
    })
      .catch((res: Response) => {
        message.destroy();
        message.error(res.data ? res.data.info : '系统异常,请联系管理员');
      });
  });

  /*
  *找回密码
  */
  @observable public currentStep = 0; // 0-验证账号，1-验证手机号， 2-设置新密码

  @observable public findPasswordFormRef = React.createRef<FormInstance>();

  @observable public username: string;

  @observable public code: string;

  @observable public newPassword: string;

  @observable public newRepeatPassword: string;

  @observable public errorInfo = ''; // 后端返回的错误信息

  @observable public isSendCode = false;

  @observable public volidMobile: string;

  @observable public volidToken: string;

  // 回到上一步
  public goBackPreviousStep = action(() => {
    this.currentStep -= 1;
    this.errorInfo = '';
  });

  // 表单验证
  public handleFindPasswordFieldsChange = action((changeFields, allFields) => {
    // 解决后端错误信息与前端表单错误重叠
    this.errorInfo = '';
    if (!changeFields.length) {
      return;
    }
    if (!changeFields[0].value) {
      this.errorInfo = '';
    }
  });

  public handleFormVerify = action((data: FindPasswordForm) => {
    const queryList = [
      () => this.checkoutUserName(data),
      () => this.handleValideCode(data),
      () => this.handleValidePassword(data),
    ];
    queryList[this.currentStep]();
  });

  // 验证账号
  public checkoutUserName = action((data: FindPasswordForm) => {
    request({ url: `${api.checkUserName}?username=${data.username}&accountType=${this.accountType}` }).then((res: Response) => {
      if (res.data && !res.data.mobile) {
        this.errorInfo = '账号不存在';
        return;
      }
      const { token, mobile } = res.data;
      this.volidToken = token;
      this.volidMobile = mobile;
      this.currentStep = 1;
      this.errorInfo = '';
    })
      .catch((res: Response) => {
        message.destroy();
        this.errorInfo = res.data ? res.data.info : '账号不存在';
      });
  });

  // 验证图形验证码
  public showImageCode = (id: string, x: number, y: number): void => {
    if (!this.volidMobile) {
      message.error('请输入手机号');
      return;
    }
    console.log('x,y', x, y);
    new SlideVerify({
      width: 240,
      height: 120,
      x,
      y,
      parentId: id,
      sliderText: '拖动滑块完成拼图，获取手机验证码',
      onSuccess: () => {
        // 发送验证码
        request({
          data: { mobile: this.volidMobile },
          method: 'post',
          url: api.sendCode,
        }).then((res: Response) => {
          this.isSendCode = true;
          this.countDown = 60;
          this.handleCountDown();
        })
          .catch((res: Response) => {
            message.error(res.data.data);
          });
      },
    });
  };

  // 倒计时
  public handleCountDown = action(() => {
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.countDown -= 1;
      if (this.countDown < 1) {
        clearInterval(this.timer);
        this.isSendCode = false;
      }
    }, 1000);
  });

  // 验证数字验证码
  public handleValideCode = (data: FindPasswordForm): void => {
    request({ url: `${api.validateCode}?code=${data.code}&mobile=${this.volidMobile}&token=${this.volidToken}&accountType=${this.accountType}` }).then(() => {
      this.currentStep = 2;
    })
      .catch((res: Response) => {
        this.errorInfo = res.data.data;
      });
  };

  // 验证密码
  public handleValidePassword = (data: FindPasswordForm): void => {
    request({
      method: 'post',
      url: api.newPassword,
      data: {
        password: data.newPassword,
        token: this.volidToken,
        accountType: this.accountType,
      },
    }).then(() => {
      message.success('密码修改成功');
      setTimeout(() => {
        window.location.href = this.loginPath;
      }, 2000);
    })
      .catch((res: Response) => {
        this.errorInfo = res.data.data;
      });
  };
}
